<template>
  <div
    v-if="!isLoading"
    class="exam-result"
  >
    <SHeader
      :breadcrumbs="breadcrumbs"
      class="exam-result__header"
      :icon="iconHeader(assessmentType)"
      :tabs="tabsVisible ? tabs : []"
      :title="headerTitle"
      toolbar
      @back="back()"
      @select-tab="goToView($event)"
    >
      <template #actions>
        <div
          class="csat-button"
          @click="handleNPS()"
        >
          <SasIcon type="star" />
          <div>Avalie sua experiência</div>
        </div>
      </template>
      <template #right>
        <SyllabusRedirectButton v-if="syllabusLinkIsVisible" />
      </template>
    </SHeader>
    <router-view @select-tab="selectTabFromRoute($event)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import moment from 'moment'
import mediaQueries from '@/mixins/mediaQueries'

import typeform from '@/services/typeform'
import SyllabusRedirectButton from '@/components/Syllabus/SyllabusRedirectButton'

import SHeader from '@/components/SHeader'

export default {
  name: 'ExamResult',

  components: {
    SHeader,
    SyllabusRedirectButton,
  },

  mixins: [mediaQueries],

  props: {
    applicationId: {
      type: [Number, String],
      required: true,
    },

    assessmentId: {
      type: [Number, String],
      required: true,
    },

    examId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      tabs: [],
    }
  },

  computed: {
    ...mapGetters([
      'assessmentType',
      'assessment',
      'exam',
      'isRankedAssessment',
      'isGradedByIrt',
      'isLoading',
      'isDigitalEnem',
      'user',
    ]),

    typeSlug() {
      const { typeSlug } = this.assessment

      return typeSlug
    },

    tabsVisible() {
      return this.$route.meta.tabsVisible
        && !['provinha', 'olimpiadas'].includes(this.typeSlug)
    },

    iconHeader() {
      return ({ imageUrl }) => (this.typeSlug !== 'provinha' ? imageUrl : '')
    },

    possibleTabs() {
      const RANKED_INDEX = 2

      return [
        {
          title: 'Visão Geral',
          index: 0,
          active: false,
          routeName: 'General',
        },
        {
          title: 'Desempenho',
          index: 1,
          active: false,
          routeName: 'Performance',
        },
        {
          title: 'Questões',
          index: this.isRankedAssessment ? RANKED_INDEX : 1,
          active: false,
          routeName: 'Items',
        },
        {
          title: 'Redação',
          // eslint-disable-next-line no-magic-numbers
          index: this.isRankedAssessment ? RANKED_INDEX + 1 : 2,
          active: false,
          routeName: 'Essay',
        },
      ]
    },

    visibleTabs() {
      if (this.isDigitalEnem) {
        return this.possibleTabs
      }

      return this.possibleTabs.filter(
        (tab) => (this.assessment.typeSlug !== 'sistematica'
          ? tab.routeName !== 'Performance'
          : tab
        ),
      )
    },

    breadcrumbs() {
      return this.isRankedAssessment
        ? 'Avaliações'
        : `${this.assessmentName} / Resultados`
    },

    headerTitle() {
      return this.isRankedAssessment
        ? this.assessmentName
        : `Seu resultado em ${this.exam.name}`
    },

    assessmentName() {
      return this.assessment.name
    },

    params() {
      const { applicationId, assessmentId, examId } = this

      return {
        applicationId,
        assessmentId,
        examId,
      }
    },

    syllabusLinkIsVisible() {
      const isEligibleType = this.typeSlug === 'enem' || this.typeSlug === 'fuvest'

      return isEligibleType && (this.mq_xl && !this.mq_l)
    },
  },

  created() {
    this.load()
    this.getSyllabusInfo(this.assessmentId)
  },

  destroyed() {
    this.clearExams()
  },

  methods: {
    ...mapActions([
      'getAssessment',
      'getExam',
      'clearExams',
      'getResults',
      'showMainLoading',
      'hideMainLoading',
      'getSyllabusInfo',
      'getUser',
    ]),

    async load() {
      const { assessmentId, examId } = this

      this.showMainLoading()

      await Promise.all([
        this.getAssessment(assessmentId),
        this.getExam({
          assessmentId,
          examId,
        }),
      ])

      this.loadTabs()
      this.hideMainLoading()
    },

    loadTabs() {
      this.tabs = this.isRankedAssessment
        ? [...this.tabs, this.performanceTab, this.itemsTab]
        : [...this.tabs, this.itemsTab]
    },

    goToView(indexTab) {
      const tab = this.tabs.find(({ index }) => index === indexTab)

      this.$router.replace({
        name: `examResult${tab.routeName}`,
        params: {
          assessmentId: this.assessmentId,
          applicationId: this.applicationId,
          examId: this.examId,
        },
      })
    },

    selectTabFromRoute(routeName) {
      this.tabs = this.visibleTabs

      const tab = this.tabs.find(
        (item) => routeName.includes(item.routeName),
      )

      this.selectTab(tab.index)
    },

    selectTab(index) {
      this.tabs = this.tabs.map((tab) => ({
        ...tab,
        active: false,
      }))
      this.tabs = this.tabs.map((tab) => (tab.index === index ? {
        ...tab,
        active: true,
      } : tab))
    },

    back() {
      const route = this.isRankedAssessment
        ? { name: 'assessments' }
        : {
          name: 'assessmentResults',
          params: this.params,
        }
      this.$router.push(route)
    },
    async handleNPS() {
      const formId = 'JfPYsQQ4'
      await this.getUser()
      const { id, name, school } = this.user
      const currentUrl = window.location.href
      const { typeSlug } = this.assessment
      const { grade } = this.assessment
      const currentDate = moment().format('YYYY-MM-DD')

      const hiddenFields = {
        account_id: id,
        account_name: name,
        school_id: school.id,
        school_name: school.name,
        date: currentDate,
        url: currentUrl,
        type: typeSlug,
        class: grade,
      }

      const typeformPopup = typeform.createPopup({
        formId,
        hiddenFields,
        options: {
          hideScrollbars: true,
          mode: 'popup',
          size: 75,
        },
      })

      typeformPopup.open()
    },
  },
}
</script>

<style lang="sass">
  .drawer__info-header
    h5
      font-size: $font-size-m

  .drawer__info-header
    .info-2
      .info__content
        label
          font-size: $font-size-s

  .drawer__info-footer
    .info__content
      .info__text
        h5
          font-size: $font-size-m

      .info__label
        font-size: $font-size-s

  .csat-button
    color: $color_ink_light
    display: flex
    flex-direction: row
    align-items: center
    &:hover
      cursor: pointer
</style>
