<template>
  <SasButtom
    id="button-tour-step"
    class="syllabus-button"
    size="big"
    @click="goToSyllabus()"
  >
    Exercícios personalizados
  </SasButtom>
</template>

<script>

import SasButtom from '@/components/global/SasButtom'
import urls from '@/utils/urls'

export default {
  name: 'SyllabusRedirectButton',

  components: {
    SasButtom,
  },

  methods: {
    goToSyllabus() {
      window.open(urls.ASSESSMENTS_INSIGHTS_URL)
    },
  },

}
</script>

<style lang="scss" scoped>
.syllabus-button {
  z-index: 1029;
}

#button-tour-step.v-tour__target--highlighted {
  box-shadow: 0 0 0 9999px rgba(30, 33, 36, 0.4) !important;
}
</style>
